import store from '@julaya/common/store';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

import '@julaya/common/scss/fonts.scss';
import { Avatar } from '@julaya/common/system';
import { isAndroid, isIOS } from 'react-device-detect';
import { Button, Modal, ModalBody } from 'reactstrap';
import 'scss/index.scss';

const onClickAppRedirect = () => {
  if (isAndroid) {
    window.location.href = 'https://play.google.com/store/apps/details?id=co.julaya.pro&hl=fr';
  } else if (isIOS) {
    window.location.href = 'https://apps.apple.com/fr/app/julaya/id6737479571';
  }
};

function AppModal() {
  return (
    <Modal isOpen={true} size="md" centered={true}>
      <ModalBody className="p-0 modal-app-container">
        <div className="d-flex px-4 pt-4 pb-2 ">
          <div className="w-100 d-flex flex-column align-items-center justifiy-content-center">
            <Avatar size="xl" className="mx-auto avatar-img" icon="sparkles" background="#d1d0ff" />
            <h6 className="mt-4 font-weight-bold">
              L’application Julaya est disponible !
            </h6>
          </div>
        </div>
        <p className="px-4 pb-1 font-weight-normal text-popup text-center">
          Pour accéder à toutes nos fonctionnalités, téléchargez l'application Julaya
          dès maintenant et gérez vos finances en toute simplicité !
          <Button
            onClick={() => onClickAppRedirect()}
            outline
            color="dark"
            className="border-0 w-100 mt-4"
          >
            Télécharger l’application
          </Button>
        </p>
      </ModalBody>
    </Modal>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Route exact path="/" component={AppModal} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('app')
);
